import styled from 'styled-components'

interface StatusBannerProps {
  status: string
}

const StatusBanner = ({ status }: StatusBannerProps) => {
  return (
    <StatusBannerWrapper className={`status-box ${status.toLowerCase()?.replace(' ', '-')}`}>
      {status}
    </StatusBannerWrapper>
  )
}

export default StatusBanner

const StatusBannerWrapper = styled.div`
  &.status-box {
    text-align: center;
    padding: 0.7rem 1rem;
    border-radius: 4px;

    max-width: 12rem;

    &.pending,
    &.scheduled,
    &.in-progress,
    &.initial {
      background: #fffbeb;
      border: 1px solid #ffce32;
      color: #ffce32;
    }
    &.processed,
    &.onboarded,
    &.active,
    &.paid {
      background: #e3f2de;
      border: 1px solid #44cc00;
      color: #44cc00;
    }
    &.failed,
    &.cancelled,
    &.rejected,
    &.pending-cancellation {
      background: #f5e3e3;
      border: 1px solid #ff3131;
      color: #ff3131;
    }
    &.not-verified,
    &.not-started,
    &.under-review {
      background: #edf0f1;
      border: 1px solid #a7b3be;
      color: #85a0ad;
    }
  }
`
