// noinspection CssUnusedSymbol

import styled from 'styled-components'

import ADA from '../../images/currencies/ada.png'
import AUD from '../../images/currencies/aud.png'
import BNB from '../../images/currencies/bnb.png'
import BRL from '../../images/currencies/brl.png'
import BTC from '../../images/currencies/btc.png'
import CAD from '../../images/currencies/cad.png'
import CLP from '../../images/currencies/clp.png'
import DOGE from '../../images/currencies/doge.png'
import ETH from '../../images/currencies/eth.png'
import EUR from '../../images/currencies/eur.png'
import GBP from '../../images/currencies/gbp.png'
import INR from '../../images/currencies/inr.png'
import JPY from '../../images/currencies/jpy.png'
import LTC from '../../images/currencies/ltc.png'
import NOK from '../../images/currencies/nok.png'
import NZD from '../../images/currencies/nzd.png'
import PEN from '../../images/currencies/pen.png'
import PLN from '../../images/currencies/pln.png'
import SOL from '../../images/currencies/sol.png'
import TRY from '../../images/currencies/try.png'
import USD from '../../images/currencies/usd.png'
import USDC from '../../images/currencies/usdc.png'
import USDT from '../../images/currencies/usdt.png'
import XRP from '../../images/currencies/xrp.png'

export const CurrencyIconWrapper = styled.span<{ width: string; height: string }>`
  display: inline-block;
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  border-radius: 8px;

  &.card {
    border: 1px solid #ffffff;
  }

  &.circle {
    border-radius: 50%;
  }

  &.ADA {
    background-image: url(${ADA});
  }
  &.AUD {
    background-image: url(${AUD});
  }
  &.BNB {
    background-image: url(${BNB});
  }
  &.BRL {
    background-image: url(${BRL});
  }
  &.BTC {
    background-image: url(${BTC});
  }
  &.CAD {
    background-image: url(${CAD});
  }
  &.CLP {
    background-image: url(${CLP});
  }
  &.DOGE {
    background-image: url(${DOGE});
  }
  &.ETH {
    background-image: url(${ETH});
  }
  &.EUR {
    background-image: url(${EUR});
  }
  &.GBP {
    background-image: url(${GBP});
  }
  &.INR {
    background-image: url(${INR});
  }
  &.JPY {
    background-image: url(${JPY});
  }
  &.LTC {
    background-image: url(${LTC});
  }
  &.NOK {
    background-image: url(${NOK});
  }
  &.NZD {
    background-image: url(${NZD});
  }
  &.PEN {
    background-image: url(${PEN});
  }
  &.PLN {
    background-image: url(${PLN});
  }
  &.SOL {
    background-image: url(${SOL});
  }
  &.TRY {
    background-image: url(${TRY});
  }
  &.USD {
    background-image: url(${USD});
  }
  &.USDC,
  &.USDC-ERC20,
  &.USDC-TRC20 {
    background-image: url(${USDC});
  }
  &.USDT,
  &.USDT-ERC20,
  &.USDT-TRC20 {
    background-image: url(${USDT});
  }
  &.XRP {
    background-image: url(${XRP});
  }
`
