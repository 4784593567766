import React, { memo } from 'react'
import parse from 'html-react-parser'
import styled from 'styled-components'
import isEqual from 'lodash.isequal'
import PropTypes from 'prop-types'

import { tr } from 'mmfintech-commons'
import DomPurify from 'dompurify'
import { getInvoiceStatusName } from 'mmfintech-backend-api'

import iconClose from '../images/icons/icon-close-gray.svg'
import iconCloseHover from '../images/icons/icon-close-gray-hover.svg'
import iconClosePaid from '../images/icons/icon-close-green.svg'
import iconClosePaidHover from '../images/icons/icon-close-green-hover.svg'
import iconCloseCancelled from '../images/icons/icon-close-darkgrey.svg'

const InvoicePreview = props => {
  const { status, content, onClose } = props

  return (
    <Wrapper data-test='invoice-preview-modal'>
      <CloseIconRow className={'status-' + status?.toLowerCase()}>
        <span>
          <span className='status-title'>{tr('FRONTEND.INVOICES.PREVIEW.STATUS', 'Invoice Status')}: </span>
          <span>{getInvoiceStatusName(status)}</span>
        </span>

        <CloseIconWrap>
          <CloseIcon onClick={onClose} />
        </CloseIconWrap>
      </CloseIconRow>

      <Content>{parse(DomPurify.sanitize(content))}</Content>
    </Wrapper>
  )
}

export default memo(InvoicePreview, (prevProps, nextProps) => isEqual(prevProps, nextProps))

InvoicePreview.propTypes = {
  status: PropTypes.string,
  content: PropTypes.string,
  onClose: PropTypes.func.isRequired
}

const Wrapper = styled.div`
  .invoice-preview {
    padding: 0;
    margin: 0 auto;
    max-width: 69.9em;

    background: #ffffff;
    color: #000000;
    font-family: inherit;
    font-style: normal;
    font-weight: normal;
    font-size: 1rem;

    @media (min-width: 500px) {
      font-size: 1.6rem;
    }
  }

  .invoice-preview-header {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: space-between;
    margin-bottom: 3.1em;
  }
  .invoice-preview-header-left {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: flex-start;
  }
  .invoice-preview-logo-wrap {
    margin-right: 1rem;
  }
  .invoice-preview-logo {
    max-height: 6rem;
    max-width: 8rem;
  }
  .invoice-preview-head-col {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: left;
  }

  table.invoice-preview-table {
    border-collapse: collapse;
    width: 100%;

    color: ${({ theme }) => theme.contentWrapper.textColor};
    font-family: inherit;
    font-size: 0.8em;
    font-style: normal;
    font-weight: normal;

    margin-bottom: 3.8em;
  }
  table.invoice-preview-table thead tr th {
    text-align: left;
    padding: 1.3em 0.77em;
    font-weight: 600;
    color: ${({ theme }) => theme.dataTable.head.textColor};
    background: ${({ theme }) => theme.dataTable.head.background};
    white-space: nowrap;
  }
  table.invoice-preview-table thead tr th:first-of-type {
    border-radius: 0.77em 0 0 0.77em;
  }
  table.invoice-preview-table thead tr th:last-of-type {
    border-radius: 0 0.77em 0.77em 0;
  }
  table.invoice-preview-table tbody tr {
    border-bottom: 1px solid #e6e6e6;
  }
  table.invoice-preview-table tbody tr td {
    text-align: left;
    padding: 1.3em 0.77em;
  }
  table.invoice-preview-table tbody tr td:first-of-type {
    padding-left: 1em;
  }

  .invoice-preview-footer {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
  }
  .invoice-preview-notes {
    margin-right: 2.2em;
    text-align: left;
    font-size: 0.86em;
  }
  .invoice-preview-notes .label {
    font-family: inherit;
    font-style: normal;
    font-weight: 600;
    font-size: 1em;
    color: #939ea7;
  }
  .invoice-preview-totals {
    display: grid;
    grid-template-columns: auto 10em !important;
    grid-template-rows: auto auto auto;
    column-gap: 1.84em;
    row-gap: 0.3em;
    text-align: left;
  }
  .text-right {
    text-align: right !important;
  }
  .font-bold {
    font-size: 1.8em;
    font-weight: bold;
    padding-top: 1em;
  }
  .fs-12 {
    font-size: 0.75em;
  }
  .fs-14 {
    font-size: 0.85em;
  }
  .fs-16 {
    font-size: 1em;
  }
  .fs-20 {
    font-weight: bold;
    font-size: 1.2em;
  }
  .pt-20 {
    padding-top: 1em;
  }
`

const CloseIconRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  font-size: 1.6rem;
  padding: 1rem 2rem;
  font-style: normal;
  font-weight: normal;
  font-family: inherit;
  line-height: 2.5rem;
  border-radius: 3px 3px 0 0;
  color: #ffffff;
  background: #a3b8c2;

  span.status-title {
    font-weight: bold;
  }

  &.status-paid {
    color: #ffffff;
    background: #59b300;
  }
  &.status-canceled {
    color: #ffffff;
    background: #000000;
  }
`

const CloseIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  width: 4rem;
  height: 4rem;
`

const CloseIcon = styled.span`
  width: 4rem;
  height: 4rem;
  cursor: pointer;
  background: url(${iconClose}) center center no-repeat;

  &:hover {
    background: url(${iconCloseHover}) center center no-repeat;
  }

  .status-paid & {
    background: url(${iconClosePaid}) center center no-repeat;
  }

  .status-paid &:hover {
    background: url(${iconClosePaidHover}) center center no-repeat;
  }

  .status-canceled & {
    background: url(${iconCloseCancelled}) center center no-repeat;
  }

  .status-canceled &:hover {
    background: url(${iconCloseCancelled}) center center no-repeat;
  }
`

export const Content = styled.div`
  font-size: 50%;
  max-height: 80vh;
  min-height: 8rem;
  text-align: center;

  padding: 2rem;
  overflow-y: auto;

  @media (min-width: 450px) {
    padding: 0 5rem;
  }

  @media (min-width: 500px) {
    font-size: 80%;
  }

  @media (min-width: 700px) {
    font-size: 90%;
    padding: 4rem;
  }

  @media (min-width: 1000px) {
    font-size: 100%;
  }
`
