import { useSelector } from 'react-redux'
import { AccountOverviewRow } from './AccountsOverviewRow'
import { useUserPermissions } from '../../hooks'

// @ts-ignore
import { Preloader } from 'mmfintech-portal-commons'
import { AccountOverviewListWrapper } from './styled/accountOverview.styled'

export const AccountOverviewList = () => {
  const { isLoading, permissions } = useUserPermissions()
  const { paymentAccounts, paymentAccountsFetching } = useSelector(
    ({ banking: { paymentAccounts, paymentAccountsFetching } }: any) => ({
      paymentAccounts,
      paymentAccountsFetching
    })
  )

  if (paymentAccountsFetching || isLoading) {
    return <Preloader />
  }

  const accounts = paymentAccounts?.map((a: any) => {
    const permission = permissions?.find((perm: any) => a?.id === perm?.accountId)
    return { ...a, permission: permission?.accessLevel }
  })

  return (
    <AccountOverviewListWrapper>
      {accounts.map((account: any, i: number) => {
        return <AccountOverviewRow account={account} key={i} />
      })}
    </AccountOverviewListWrapper>
  )
}
