import { TransactionsSectionTitle, TransactionsSectionWrapper } from './styled/transactions.styled'
import TransactionsTable from '../../transactions/TransactionsTable'
import { tr } from 'mmfintech-commons'

const TransactionsSection = ({ title = '', filter = {}, showPagination = false, forceReload = false }) => (
  <TransactionsSectionWrapper>
    <TransactionsSectionTitle>
      {title || tr('FRONTEND.DASHBOARD.TRANSACTIONS_TITLE', 'Recent transactions')}
    </TransactionsSectionTitle>
    <TransactionsTable filter={filter} showPagination={showPagination} forceReload={forceReload} />
  </TransactionsSectionWrapper>
)

export default TransactionsSection
