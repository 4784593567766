import styled from 'styled-components'

export const CircleButtonWrapper = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  width: 4.5rem;
  height: 4.5rem;

  padding: 1rem;
  border-radius: 50%;
  border: 1px solid #e5e5e5;

  :hover {
    border-color: #828282;
  }
`
