// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const LogoWrapper = styled.div`
  width: 22rem;
  height: 10rem;
  position: relative;

  .image-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
  }

  .image-item img {
    max-width: 22rem;
    max-height: 10rem;
  }

  .logo-remove-item {
    position: absolute;
    top: -1.5rem;
    right: -1.5rem;
    display: none;
    cursor: pointer;
  }

  .image-item:hover .logo-remove-item {
    display: inline-block;
  }

  .logo-loader {
    display: inline-block;
    width: 22rem;
    height: 10rem;
  }

  .logo-inner {
    position: relative;
    display: inline-block;
  }
`

export const LogoButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  cursor: pointer;
  height: 100%;
  width: 100%;

  color: #85a0ad;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;

  span.sign {
    color: #000000;
    font-size: 3.2rem;
    font-weight: bold;
    margin-bottom: 1rem;
  }

  &.bordered {
    border-radius: 3px;
    width: 22rem;
    height: 10rem;
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='rgba(163, 184, 194, 0.5)' stroke-width='2' stroke-dasharray='4%2c 8' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }

  &.bordered:hover {
    color: #85a0ad;

    .caption {
      text-decoration: underline;
    }
  }
`
