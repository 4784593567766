import { configuration, initialize } from 'mmfintech-backend-api'

initialize(import.meta.env)

const settings = {
  // backendForLocalhost: 'https://portal-dev.theguardianbank.com',
  // backendForLocalhost: 'https://portal-sandbox.theguardianbank.com',
  cookieDomain: configuration.isLocal() ? null : '.theguardianbank.com',
  customerServiceUrl: 'mailto:team@theguardianbank.com',
  landingPageUrl: configuration.isDevelopment()
    ? 'https://dev.theguardianbank.com'
    : configuration.isSandbox()
    ? 'https://sandbox.theguardianbank.com'
    : 'https://theguardianbank.com',
  languages: ['en'],

  defaultUploadInvoiceAcceptType: ['pdf', 'jpg', 'jpeg', 'png'],
  defaultUploadInvoiceMaxFileSize: 1024 * 1024,
  logoAcceptType: ['jpg', 'jpeg', 'gif', 'png', 'svg'],
  logoMaxFileSize: 1024 * 1024,
  questionnaireFileTypes: ['pdf', 'jpg', 'jpeg', 'png'],
  questionnaireMaxFileSize: 1024 * 1024 * 10
}

export default settings
