import styled from 'styled-components'

import BackgroundImage from '../../images/background.png'

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(320px, 1490px) 1fr;
  grid-template-rows: ${({ theme }) => theme.header.heightThin} auto;
  grid-template-areas: 'header header header' 'left-column main-content right-column';
  grid-row-gap: ${({ theme }) => theme.contentWrapper.gridRowGap};

  margin: 0;
  padding: 0;
  min-height: 100vh;
  overflow: hidden;
  overflow-y: auto;
  position: relative;

  font-family: ${({ theme }) => theme.fontName}, sans-serif;
  background-color: ${({ theme }) => theme.contentWrapper.backgroundColor};

  @media (min-width: 900px) {
    grid-row-gap: ${({ theme }) => theme.contentWrapper.gridRowGap2};
  }

  @media (min-width: 1175px) {
    grid-template-rows: ${({ theme }) => theme.header.height} auto;
    grid-row-gap: ${({ theme }) => theme.contentWrapper.gridRowGap3};
  }

  & > div:first-of-type {
    animation: fadein 0.75s;
  }

  .fluid & {
    grid-row-gap: 0;

    @media (min-width: 900px) {
      grid-row-gap: 0;
    }

    @media (min-width: 1175px) {
      grid-template-rows: 9rem auto;
      grid-row-gap: 0;
    }
  }

  //todo inherit RoundSquareContainer in /components/styled
  .round-square-container {
    background: #ffffff;
    border-radius: ${({ theme }) => theme.containerBorderRadius};
    box-shadow: ${({ theme }) => theme.containerDropShadow};
    color: #000000;
    padding: 3rem 5rem;

    @media (max-width: 1024px) {
      padding: 2rem 3rem;
    }
  }

  .label-image-link {
    display: inline-flex;
    flex-flow: row nowrap;
    align-items: center;

    color: #a3b8c2;
    font-family: inherit;
    font-size: 1.4rem;
    font-style: normal;
    font-weight: 500;
    line-height: 1.7rem;
    cursor: pointer;

    .icon,
    img,
    svg {
      margin-right: 0.8rem;
    }
  }

  .guardian-background {
    background-image: url(${BackgroundImage});
    background-color: #000000;
    background-repeat: repeat-y;
    background-position: center top;
    background-size: cover;
  }

  .input-hint {
    color: #a3b8c2;
    font-size: 1.2rem;
    font-style: italic;
    margin: -0.5rem 0 2rem;
  }

  ${'' /* react-datepicker styling */}
  .react-datepicker {
    background-color: ${({ theme }) => theme.datepicker.container.backgroundColor};
    border-color: ${({ theme }) => theme.datepicker.container.borderColor};
    border-radius: ${({ theme }) => theme.datepicker.container.borderRadius};
    box-shadow: ${({ theme }) => theme.datepicker.container.boxShadow};
    font-family: inherit;
    font-size: ${({ theme }) => theme.datepicker.container.fontSize};
    padding: ${({ theme }) => theme.datepicker.container.padding};
  }
  .react-datepicker-wrapper {
    width: 100%;
  }
  .react-datepicker__header {
    background-color: ${({ theme }) => theme.datepicker.header.backgroundColor};
    border-bottom-color: ${({ theme }) => theme.datepicker.header.borderColor};
    border-top-left-radius: ${({ theme }) => theme.datepicker.container.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.datepicker.container.borderRadius};
    padding: ${({ theme }) => theme.datepicker.header.padding};
  }
  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    color: ${({ theme }) => theme.datepicker.header.textColor};
    font-size: ${({ theme }) => theme.datepicker.header.fontSize};
    font-weight: ${({ theme }) => theme.datepicker.header.fontWeight};
    line-height: ${({ theme }) => theme.datepicker.header.lineHeight};
  }
  .react-datepicker__day-names {
    margin-top: ${({ theme }) => theme.datepicker.dayName.marginTop};
    margin-bottom: ${({ theme }) => theme.datepicker.dayName.marginBottom};
  }
  .react-datepicker__day-name {
    color: ${({ theme }) => theme.datepicker.dayName.textColor};
    font-size: ${({ theme }) => theme.datepicker.dayName.fontSize};
    font-weight: ${({ theme }) => theme.datepicker.dayName.fontWeight};
    line-height: ${({ theme }) => theme.datepicker.dayName.lineHeight};
    margin: ${({ theme }) => theme.datepicker.cells.margin};
    width: ${({ theme }) => theme.datepicker.cells.width};
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker__navigation--next {
    top: ${({ theme }) => theme.datepicker.header.arrowTop};
    right: ${({ theme }) => theme.datepicker.header.arrowLeftRight};
  }
  .react-datepicker__navigation--previous {
    top: ${({ theme }) => theme.datepicker.header.arrowTop};
    left: ${({ theme }) => theme.datepicker.header.arrowLeftRight};
  }
  .react-datepicker__day {
    border: ${({ theme }) => theme.datepicker.cells.border};
    border-radius: ${({ theme }) => theme.datepicker.cells.borderRadius};
    font-size: ${({ theme }) => theme.datepicker.cells.fontSize};
    font-weight: ${({ theme }) => theme.datepicker.cells.fontWeight};
    line-height: ${({ theme }) => theme.datepicker.cells.height};
    margin: ${({ theme }) => theme.datepicker.cells.margin};
    width: ${({ theme }) => theme.datepicker.cells.width};
    :first-child {
      margin-left: 0;
    }
    :last-child {
      margin-right: 0;
    }
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::before {
    border-top-color: ${({ theme }) => theme.datepicker.container.borderColor};
  }
  .react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle::after {
    border-top-color: ${({ theme }) => theme.datepicker.header.backgroundColor};
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::before {
    border-bottom-color: ${({ theme }) => theme.datepicker.container.borderColor};
  }
  .react-datepicker-popper[data-placement^='bottom'] .react-datepicker__triangle::after {
    border-bottom-color: ${({ theme }) => theme.datepicker.header.backgroundColor};
  }
  .react-datepicker__navigation-icon::before {
    border-color: ${({ theme }) => theme.datepicker.header.textColor};
    border-top-width: ${({ theme }) => theme.datepicker.header.arrowSize};
    border-right-width: ${({ theme }) => theme.datepicker.header.arrowSize};
  }
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__quarter-text--keyboard-selected,
  .react-datepicker__year-text--keyboard-selected,
  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__month--in-selecting-range,
  .react-datepicker__month--in-range,
  .react-datepicker__quarter--selected,
  .react-datepicker__quarter--in-selecting-range,
  .react-datepicker__quarter--in-range {
    background-color: ${({ theme }) => theme.datepicker.cells.selectedBackground};
    border: ${({ theme }) => theme.datepicker.cells.selectedBorder};
    color: ${({ theme }) => theme.datepicker.cells.selectedColor};
  }
  .react-datepicker__day--keyboard-selected:hover,
  .react-datepicker__month-text--keyboard-selected:hover,
  .react-datepicker__quarter-text--keyboard-selected:hover,
  .react-datepicker__year-text--keyboard-selected:hover,
  .react-datepicker__day--selected:hover,
  .react-datepicker__day--in-selecting-range:hover,
  .react-datepicker__day--in-range:hover,
  .react-datepicker__month-text--selected:hover,
  .react-datepicker__month-text--in-selecting-range:hover,
  .react-datepicker__month-text--in-range:hover,
  .react-datepicker__quarter-text--selected:hover,
  .react-datepicker__quarter-text--in-selecting-range:hover,
  .react-datepicker__quarter-text--in-range:hover,
  .react-datepicker__year-text--selected:hover,
  .react-datepicker__year-text--in-selecting-range:hover,
  .react-datepicker__year-text--in-range:hover,
  .react-datepicker__month--selected:hover,
  .react-datepicker__month--in-selecting-range:hover,
  .react-datepicker__month--in-range:hover,
  .react-datepicker__quarter--selected:hover,
  .react-datepicker__quarter--in-selecting-range:hover,
  .react-datepicker__quarter--in-range:hover {
    background-color: ${({ theme }) => theme.datepicker.cells.hoverBackground};
  }
  .react-datepicker__day--outside-month {
    color: ${({ theme }) => theme.datepicker.cells.outsideColor};
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover,
  .react-datepicker__quarter-text:hover,
  .react-datepicker__year-text:hover {
    color: ${({ theme }) => theme.datepicker.cells.hoverTextColor};
    background-color: ${({ theme }) => theme.datepicker.cells.hoverBackground};
    border: ${({ theme }) => theme.datepicker.cells.hoverBorder};
  }

  .trs-2 {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  #info-tooltip {
    color: #ffffff !important;
    background: #2f3032 !important;
  }
`
