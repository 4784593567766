import { Link } from 'react-router-dom'
import { useSelector, shallowEqual } from 'react-redux'

import { tr } from 'mmfintech-commons'
import { useLogin, paths } from 'mmfintech-backend-api'

import { GuardianLogo } from '../../../components'
import { Button, ErrorDisplay, Input } from 'mmfintech-portal-commons'
import {
  LoginContainer,
  LoginContent,
  LoginContentWrapper,
  LoginWrapper,
  SignupLinkWrapper,
  StyledForm
} from './Login.styled'

export const Login = () => {
  const { fetching, otpRequestError, loginError, loginFetching } = useSelector(
    ({ otp: { fetching, otpRequestError }, user: { loginError, loginFetching, selectedLanguage } }: any) => ({
      fetching,
      otpRequestError,
      loginError,
      loginFetching,
      selectedLanguage
    }),
    shallowEqual
  )

  const { formValues, handleSubmit } = useLogin()

  return (
    <LoginWrapper>
      <LoginContainer>
        <GuardianLogo />

        <LoginContentWrapper>
          <LoginContent>
            <div className='heading'>{tr('FRONTEND.LOGIN.TITLE', 'Sign In')}</div>

            <StyledForm noValidate onSubmit={handleSubmit}>
              <Input
                id='email'
                type='email'
                data-test='email'
                label={tr('FRONTEND.LOGIN.EMAIL', 'E-mail Address')}
                {...formValues.registerInput('email')}
                disabled={fetching || loginFetching}
                hideErrorLine
                hideRequired
                autoFocus
              />

              <Input
                id='password'
                type='password'
                data-test='password'
                label={tr('FRONTEND.LOGIN.PASSWORD', 'Password')}
                {...formValues.registerInput('password')}
                disabled={fetching || loginFetching}
                autoComplete='off'
                hideErrorLine
                hideRequired
              />

              <div className='link-wrap' data-test='forgot-password'>
                <Link to={paths.forgotPassword()}>{tr('FRONTEND.LOGIN.FORGOT_PASSWORD', 'Forgot password?')}</Link>
              </div>

              <ErrorDisplay error={[loginError, otpRequestError]} />

              <Button
                type='submit'
                color='alternative'
                text={tr('FRONTEND.LOGIN.BUTTON_LOGIN', 'Log in')}
                loading={fetching || loginFetching}
                data-test='button-submit'
              />
            </StyledForm>
          </LoginContent>

          <SignupLinkWrapper>
            {tr('FRONTEND.LOGIN.NO_ACCOUNT', "Don't have an account?")}
            <Link to={paths.signUp()} data-test='sign-up'>
              <span>{tr('FRONTEND.LOGIN.SIGNUP_BUTTON', 'Sign up')} →</span>
            </Link>
          </SignupLinkWrapper>
        </LoginContentWrapper>

        <span />
      </LoginContainer>
    </LoginWrapper>
  )
}
