// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const AccessDeniedContainer = styled.div`
  grid-area: main-content;
  display: block;

  .text-container {
    max-width: 50rem;
    margin: 0 auto;
    text-align: center;

    .title {
      font-size: 3.6rem;
    }

    .headline {
      color: #000000;
      font-weight: 500;
    }

    .button-back {
      max-width: 36rem;
      margin-top: 2rem;

      background: #ffffff;
      color: #000000;
      font-size: 1.8rem;

      :focus,
      :hover {
        background: rgba(255, 255, 255, 0.8);
        color: #000000;
      }
    }
  }
`
