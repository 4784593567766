import styled from 'styled-components'

import { Button } from 'mmfintech-portal-commons'

import { useCurrencies } from 'mmfintech-backend-api'
import { formatMoney, tr } from 'mmfintech-commons'

interface ApplicationFeeInfoProps {
  amount: number
  currency: string
  onClose: () => void
  onSubmit: () => void
}

const ApplicationFeeInfoModal = ({ amount, currency, onClose, onSubmit }: ApplicationFeeInfoProps) => {
  const { getCurrencyPrecision } = useCurrencies()

  return (
    <ApplicationFeeInfoWrapper>
      <div className='title'>{tr('FRONTEND.APPLICATION_FEE.INFO.TITLE', 'Application Fee Details')}</div>
      <div className='amount-wrapper'>
        <div className='amount-label'>{tr('FRONTEND.APPLICATION_FEE.INFO.AMOUNT.LABEL', 'Amount')}</div>
        <div className='amount'>{formatMoney(amount, currency, getCurrencyPrecision(currency))}</div>
      </div>
      <div className='buttons-container'>
        <Button
          type='button'
          color='primary'
          text={tr('FRONTEND.APPLICATION_FEE.INFO.PAY.BUTTON', 'Pay')}
          data-test='button-pay'
          onClick={onSubmit}
        />
        <Button
          type='button'
          color='secondary'
          text={tr('FRONTEND.BUTTONS.CANCEL', 'Cancel')}
          data-test='button-cancel'
          onClick={onClose}
        />
      </div>
    </ApplicationFeeInfoWrapper>
  )
}

export default ApplicationFeeInfoModal

const ApplicationFeeInfoWrapper = styled.div`
  width: 92vw;
  max-width: 50rem;

  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  padding: 3rem 2rem;

  display: flex;
  flex-flow: column nowrap;
  gap: 3rem;

  @media (min-width: 940px) {
    padding: 4rem 5rem;
  }

  .title {
    display: block;
    color: #000000;
    font-size: 1.8rem;
    font-weight: 400;
    line-height: 2.2rem;
    text-align: left;
  }
  .amount-wrapper {
    background: #f4f6f6;
    border-radius: 0.4rem;
    padding: 1rem 2rem;
    width: 100%;
  }
  .amount-label {
    color: #85a0ad;
    font-size: 1.2rem;
    line-height: 1.5rem;
  }

  .amount {
    font-weight: 600;
    font-size: 2rem;
    margin-top: 0.5rem;
  }

  button:first-of-type {
    margin-bottom: 1rem;
  }
`
