import styled from 'styled-components'

import ChevronRightIcon from '../../images/icons/arrow-right.svg'
import ChevronRightHoverIcon from '../../images/icons/arrow-right-white.svg'

export const ArrowRight = styled.span`
  float: right;
  display: flex;
  width: 5rem;
  height: 100%;
  border-radius: 0 3px 3px 0;
  background: url(${ChevronRightIcon}) center center no-repeat;

  .data-table-hover &:hover {
    background: rgba(163, 184, 194, 0.5) url(${ChevronRightHoverIcon}) center center no-repeat;
  }
`
