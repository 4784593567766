// noinspection CssUnusedSymbol

import styled from 'styled-components'

export const SelectAccountWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
`

export const SelectAccountLabel = styled.div`
  color: #85a0ad;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem;

  margin-bottom: 5px;
  margin-left: 2rem;
`

export const SelectAccountStyled = styled.div`
  width: 100%;
  position: relative;
`

export const AccountButtonStyled = styled.div`
  background: #ffffff;
  border: 1px solid rgba(133, 160, 173, 0.2);
  border-radius: 4px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

  width: 100%;
  height: 4.6rem;
  padding-left: 1rem;
  padding-right: 2rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between;

  color: #85a0ad;
  font-family: inherit;
  font-size: 1.2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;

  &.error {
    border-color: rgba(255, 49, 49, 0.7);
  }
  &.static {
    cursor: default;
  }

  &.no-shadow {
    box-shadow: none;
  }

  .account-info {
    display: flex;
    align-items: center;

    .icon {
      width: 26px;
      height: 26px;
      margin-right: 1rem;
    }

    .content {
      display: flex;
      flex-flow: column nowrap;

      .balance {
        color: #000000;
        font-family: inherit;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: 500;
        line-height: 120%;

        b {
          font-weight: 700;
        }
      }
    }
  }

  .no-account {
    color: #000000;
    font-size: 1.4rem;
    font-weight: 500;
    line-height: 1.7rem;
    margin-left: 2rem;
  }
`

export const AccountListStyled = styled.div<{ opened: boolean }>`
  display: ${({ opened }) => (opened ? 'block' : 'none')};
  position: absolute;
  top: 5.5rem;
  left: 0;
  right: 0;
  z-index: 101;

  background: #ffffff;
  border-radius: 6px;
  box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
  padding: 1rem 0;

  overflow-y: auto;
  //max-height: 26rem;

  color: #85a0ad;
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
`

export const AccountStyled = styled.div`
  width: 100%;
  height: 4rem;
  cursor: pointer;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 0 1rem;

  :hover {
    background: rgba(220, 223, 227, 0.4);
  }

  &.disabled {
    pointer-events: none;
  }

  .icon {
    width: 26px;
    height: 26px;
    margin-right: 1rem;
  }

  .icon-all {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem;
    height: 2.5rem;
    background: #85a0ad;
    border-radius: 4px;
    color: #ffffff;
    font-family: inherit;
    font-size: 1rem;
    font-style: normal;
    line-height: 1.2rem;
  }

  .content {
    display: flex;
    flex-flow: column nowrap;

    .balance {
      color: #000000;
      font-weight: 500;
      b {
        font-weight: 700;
      }
    }
  }
`

export const SelectAccountError = styled.div`
  color: #ff3131;
  font-family: inherit;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  margin-top: 5px;
  margin-left: 2.5rem;
`

export const AddButtonWrapper = styled.div`
  display: flex;
  justify-content: center;

  margin: 1rem 3rem;

  .button {
    min-height: auto;
    height: 3.5rem;
    width: 100%;
    max-width: 30rem;

    color: #ffffff;
    font-size: 12px;
    line-height: 120%;
    font-weight: 500;

    border-radius: 6px;
  }
`
export const OtherOptionsStyled = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  padding: 1rem;

  background-color: #85a0ad;

  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
`
