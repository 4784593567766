import styled from 'styled-components'

export const JumpingButtonWrapper = styled.div`
  width: 100%;
  height: 10rem;

  :hover {
    .button {
      color: #000000 !important;
      box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
    }
  }

  @media (min-width: 830px) {
    :hover {
      .button {
        transform: translateY(-1rem);
      }
    }
  }
`

export const JumpingButtonStyled = styled.div`
  height: 10rem;
  padding: 2rem 1rem !important;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  color: #a3b8c2 !important;
  font-family: inherit;
  font-size: 1.6rem;
  font-style: normal;
  font-weight: normal;
  line-height: 1.9rem;
  text-transform: capitalize;

  cursor: pointer;
  transition: transform 500ms;

  img,
  svg {
    margin-bottom: 1rem;
    width: auto;
    height: auto;
  }
`
