import { useEffect } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import StatusBanner from '../dashboard/elements/StatusBanner'

import { DataTable, NoElementsMessage, ErrorDisplay, Pagination } from 'mmfintech-portal-commons'
import { TransactionsTableContainer, TransactionsWrapper } from '../dashboard/elements/styled/transactions.styled'

import { utils, tr, usePagination, fetchLastChunk, formatMoney } from 'mmfintech-commons'
import { actions, formatTransactionDescription, getTransactionStatusName, useCurrencies } from 'mmfintech-backend-api'

const TransactionsTable = ({ filter, showPagination, forceReload }) => {
  const { transactions, transactionsError, transactionsFetching } = useSelector(
    ({ banking: { transactions, transactionsFetching, transactionsError } }) => ({
      transactions,
      transactionsError,
      transactionsFetching
    }),
    shallowEqual
  )

  const dispatch = useDispatch()
  const { getCurrencyPrecision } = useCurrencies()

  const pagination = usePagination({
    rowsPerPage: 5,
    reload: (params, onSuccess) => {
      const transactionFilter = {
        ...filter,
        ...params
      }
      dispatch(actions.banking.listTransactions(transactionFilter, onSuccess))
    }
  })

  useEffect(() => {
    pagination.reload()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, forceReload])

  return (
    <TransactionsWrapper>
      {transactionsFetching ? null : transactionsError ? (
        <ErrorDisplay error={transactionsError} />
      ) : (
        <TransactionsTableContainer>
          {utils.is.validArray(transactions) ? (
            <>
              <DataTable.Table scale2='1000px' skipHover={true}>
                <DataTable.Head>
                  <DataTable.Row>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')}</DataTable.HeadCell>
                    <DataTable.HeadCell>
                      {tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                    </DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}</DataTable.HeadCell>
                    <DataTable.HeadCell>{tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}</DataTable.HeadCell>
                  </DataTable.Row>
                </DataTable.Head>

                <DataTable.Body>
                  {transactions.map((data, index) => {
                    const { id, reqTime, amount, currency, direction, status, trnTypeLocalizationKey } = data

                    return (
                      <DataTable.Row key={index} data-test='table-row'>
                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.ID', 'ID')} data-test='id'>
                          {id}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DATE', 'Date')}
                          className='no-wrap'
                          data-test='date'>
                          {utils.format.formatDate(reqTime)}
                        </DataTable.Cell>

                        <DataTable.Cell caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.TYPE', 'Type')} data-test='type'>
                          {tr(trnTypeLocalizationKey, fetchLastChunk(trnTypeLocalizationKey))}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.DESCRIPTION', 'Description')}
                          data-test='description'>
                          {formatTransactionDescription(data)}
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.STATUS', 'Status')}
                          className={'text-upper status-' + status.toLowerCase()}
                          data-test='status'>
                          <StatusBanner status={getTransactionStatusName(status)} />
                        </DataTable.Cell>

                        <DataTable.Cell
                          caption={tr('FRONTEND.TRANSACTIONS.COLUMNS.AMOUNT', 'Amount')}
                          data-test='amount'>
                          {formatMoney(
                            amount * (direction === 'WITHDRAW' ? -1 : 1) || 0,
                            currency || '',
                            getCurrencyPrecision(currency)
                          )}
                        </DataTable.Cell>
                      </DataTable.Row>
                    )
                  })}
                  <DataTable.Row>
                    <DataTable.Cell colSpan='6' className='pagination-cell'>
                      {showPagination && <Pagination className='customers-pagination' {...pagination.register()} />}
                    </DataTable.Cell>
                  </DataTable.Row>
                </DataTable.Body>
              </DataTable.Table>
            </>
          ) : (
            <NoElementsMessage
              message={tr('FRONTEND.DASHBOARD.NO_TRANSACTIONS', "You don't have any transactions yet")}
            />
          )}
        </TransactionsTableContainer>
      )}
    </TransactionsWrapper>
  )
}

export default TransactionsTable
