import styled from 'styled-components'

type ProgressBarWithInfoProps = {
  monthlyVolume: number
  percentage: number
}
export const SpendingLimitBarWrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
`
export const SpentAmount = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  color: rgba(133, 160, 173, 1);

  .available-text {
    color: rgba(0, 0, 0, 1);

    @media (min-width: 1200px) {
      padding-left: 1rem;
    }
  }
`
export const ProgressBarInfoWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-bottom: 1rem;

  width: 100%;

  font-weight: 600;
  font-size: 1.2rem;
  line-height: 120%;

  @media (min-width: 550px) {
    min-width: 45rem;
  }

  div:last-of-type {
    text-align: right;
  }

  .info-text {
    color: #85a0ad;
    font-weight: 400;

    margin-right: 0.5rem;
  }
  .infinity {
    font-size: 1.6rem;
    font-weight: 700;
  }
`
export const ProgressBarWithInfoWrapper = styled.div<ProgressBarWithInfoProps>`
  height: 40px;
  background: #ffffff;
  margin-bottom: 20px;
  border-radius: 4px;

  .progress-bar {
    display: flex;
    align-items: center;
    height: 40px;
    background: ${({ percentage, monthlyVolume }) =>
      monthlyVolume ? (percentage <= 50 ? ' #73D243' : percentage <= 80 ? '#F9BD46' : '#FF3131') : '#53B4FB'};
    border-radius: 4px;
    width: ${({ percentage }) => (percentage < 9 ? 9 : percentage)}%;
    color: white;
    justify-content: ${({ monthlyVolume }) => (monthlyVolume ? 'flex-end' : 'flex-start')};
    padding: 0 5px;

    div {
      display: flex;
      justify-content: center;
      align-items: center;

      font-weight: 600;
      font-size: 1rem;
      line-height: 1.2rem;
      position: relative;
    }
  }
`
