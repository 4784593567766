import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import thunk from 'redux-thunk'
import { enableMapSet, enableES5 } from 'immer'
import { configureStore, ConfigureStoreOptions } from '@reduxjs/toolkit'

import { mixedReducers, middlewares } from 'mmfintech-backend-api'

import './i18n'

import 'react-phone-input-2/lib/style.css'
import 'react-datepicker/dist/react-datepicker.css'
import './index.css'

import App from './App'

enableMapSet()
enableES5()

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: mixedReducers,
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat(middlewares.apiMiddleware).concat(thunk),
    ...options
  })

export const store = createStore()

export type RootState = ReturnType<typeof store.getState>
export type AppStore = ReturnType<typeof createStore>

export type AppDispatch = AppStore['dispatch']

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('guardian-bank-app')
)
