import React from 'react'
import cn from 'classnames'

import DashboardChart from './GraphChart'
import { CurrencyIcon } from '../../../components'
import { ExchangeButtonWrap, ExchangePaneWrapper, GraphPaneWrapper } from './styled/graphSection.styled'

import { useExchangePairs } from 'mmfintech-backend-api'

const GraphSection = () => {
  const { updatedFxRates } = useExchangePairs({
    fixedRates: null
  })

  return (
    <GraphPaneWrapper>
      <div className='graph'>
        <DashboardChart />
      </div>

      <ExchangePaneWrapper>
        {updatedFxRates
          ?.filter((node, index) => index < 4)
          .map((node, index) => (
            <ExchangeButton key={index} node={node} />
          ))}
      </ExchangePaneWrapper>
    </GraphPaneWrapper>
  )
}

export default GraphSection

const ExchangeButton = ({ node }) => {
  const { fromCurrency, toCurrency, rate, change } = node
  const { changePercentage } = change || {}

  return (
    <ExchangeButtonWrap>
      <div className='left'>
        <CurrencyIcon width='4rem' height='4rem' currency={fromCurrency} square />
        <div className='rate'>
          <span className='currency'>
            {fromCurrency}/{toCurrency}
          </span>
          <span className='price'>{rate}</span>
        </div>
      </div>
      <span className={cn('change', { positive: changePercentage > 0, negative: changePercentage < 0 })}>
        {changePercentage}%
      </span>
    </ExchangeButtonWrap>
  )
}
