import styled from 'styled-components'
import { CommonPageTitle } from '../../../components/guardianStyled'

export const DashboardWrapper = styled.div`
  grid-area: main-content;
  padding: 0 2.5rem;

  height: calc(100vh - 9rem);
  display: flex;
  position: relative;
  flex-flow: row nowrap;

  .no-data {
    color: #a3b8c2;
  }

  @media (max-width: 1400px) {
    height: auto;
    flex-flow: column nowrap;
  }
`

export const DashboardLeft = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 4rem;
  padding-right: 0;
  margin-bottom: 3rem;

  @media (min-width: 1400px) {
    overflow-y: auto;
    padding-top: 5rem;
    padding-right: 4rem;
    max-height: 100%;
    margin-bottom: 0;
  }
`

export const DashboardTitle = styled(CommonPageTitle)`
  margin-bottom: 1.5rem;
`

export const DashboardRight = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;

  span.line-wrap {
    width: 100%;
  }

  @media (min-width: 1400px) {
    border-left: 1px solid rgba(163, 184, 194, 0.3);
    border-right: 1px solid rgba(163, 184, 194, 0.3);

    align-items: center;
    max-width: 40rem;
    max-height: 100%;
    padding-top: 5rem;
    overflow-y: auto;
  }
`

export const DashboardRightTitle = styled.div`
  color: #85a0ad;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  text-align: center;
  text-transform: capitalize;
`
export const StaffDashboardWrapper = styled.div`
  .dashboard-nav {
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;

    margin-bottom: 2rem;

    @media (min-width: 700px) {
      flex-flow: row nowrap;
      align-items: center;
    }

    .button {
      height: 46px;
      border-radius: 4px;
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);

      color: #85a0ad;
      font-size: 1.4rem;
      font-weight: 400;
      line-height: 1.7rem;
      text-transform: capitalize;
      padding: 0 3rem;

      @media (min-width: 700px) {
        max-width: 14rem;
      }
    }
  }
`
