import { useDispatch } from 'react-redux'
import { Link, useHistory } from 'react-router-dom'

import { actions, paths } from 'mmfintech-backend-api'
import { tr, useDropDownMenu } from 'mmfintech-commons'

import { DropDownMenuContainer, DropDownMenuIcon, DropDownMenuWrapper } from './UserDropDownMenu.styled'
import React from 'react'

export const UserDropDownMenu = ({ UserMenuItems }) => {
  const { visible, hideDropDown, toggleDropDown } = useDropDownMenu()

  const history = useHistory()
  const dispatch = useDispatch()

  const handleLogout = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault()
    hideDropDown()
    history.push(paths.logout())
    dispatch(actions.auth.logout())
  }

  return (
    <DropDownMenuWrapper>
      <DropDownMenuIcon onClick={toggleDropDown}>
        <span className='menu-icon' />
      </DropDownMenuIcon>

      {visible && (
        <DropDownMenuContainer>
          <UserMenuItems onClick={hideDropDown} className='menu-item selector' />
          <div className='menu-footer'>
            <Link to='#' className='menu-item logout' onClick={handleLogout}>
              {tr('FRONTEND.HEADER.LOGOUT', 'Log Out')}
            </Link>
          </div>
        </DropDownMenuContainer>
      )}
    </DropDownMenuWrapper>
  )
}
