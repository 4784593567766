import React, { useContext, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import { GlobalContext, utils, tr } from 'mmfintech-commons'
import { actions, isOwnerOrAdministrator } from 'mmfintech-backend-api'

import GraphSection from './elements/GraphSection'
import SumSubBanner from '../onboarding/SumSubBanner'
import WelcomeModal from './elements/WelcomeModal'
import AccountSection from './elements/AccountSection'
import InvoicePreview from '../../components/InvoicePreview'
import ActivityDateLine from './elements/ActivityDateLine'
import ActivityInfoLine from './elements/ActivityInfoLine'
import TransactionsSection from './elements/TransactionsSection'

import { ErrorDisplay } from 'mmfintech-portal-commons'
import { StaffDashboard } from './StaffDashboard'
import {
  DashboardLeft,
  DashboardRight,
  DashboardRightTitle,
  DashboardTitle,
  DashboardWrapper
} from './styled/dashboard.styled'

const Dashboard = () => {
  const { modalHide, modalShow } = useContext(GlobalContext)

  const {
    customerRole,
    lastActivities,
    lastActivitiesError,
    lastActivitiesFetching,
    selectedLanguage,
    invoice,
    invoicePreview,
    aggregatedBalance,
    aggregatedBalanceError,
    initialEmailVerification
  } = useSelector(
    ({
      user: {
        customerRole,
        lastActivities,
        lastActivitiesError,
        lastActivitiesFetching,
        selectedLanguage,
        aggregatedBalance,
        aggregatedBalanceError,
        initialEmailVerification
      },
      invoices: { invoice, invoicePreview }
    }) => ({
      customerRole,
      lastActivities,
      lastActivitiesError,
      lastActivitiesFetching,
      selectedLanguage,
      invoice,
      invoicePreview,
      aggregatedBalance,
      aggregatedBalanceError,
      initialEmailVerification
    }),
    shallowEqual
  )

  const [activities, setActivities] = useState([])

  const dispatch = useDispatch()

  const reloadTransactions = () => {
    const filter = {
      page: 0,
      size: 5,
      sort: 'reqTime-desc,id-desc'
    }

    dispatch(actions.banking.listTransactions(filter))
  }

  useEffect(() => {
    dispatch(actions.kyb.getSumsubOnboardingStatus())
    // eslint-disable-next-line
  }, [dispatch])

  useEffect(() => {
    dispatch(actions.merchant.fetchLastActivities())
    reloadTransactions()
    // eslint-disable-next-line
  }, [dispatch, selectedLanguage])

  useEffect(() => {
    const list = []
    if (lastActivities) {
      lastActivities.forEach(item => {
        const dt = new Date(item.time).toDateString()
        let innerList = list.find(v => v.date === dt)
        if (innerList == null) {
          innerList = { date: dt, list: [item] }
          list.push(innerList)
        } else {
          innerList.list.push(item)
        }
      })
    }
    setActivities(list)
  }, [lastActivities])

  useEffect(() => {
    if (invoice && invoicePreview) {
      modalShow({
        options: { size: 'extra-large' },
        content: <InvoicePreview status={invoice.status} content={invoicePreview} onClose={modalHide} />
      })
      dispatch(actions.invoice.resetPreview())
    }
    // eslint-disable-next-line
  }, [invoice, invoicePreview])

  useEffect(() => {
    if (initialEmailVerification) {
      modalShow({
        options: {
          size: 'medium',
          transparent: true,
          closeOnClickOutside: false,
          closeOnEscape: false
        },
        content: <WelcomeModal />
      })
      dispatch(actions.auth.verifyEmailCleanup())
    }
    // eslint-disable-next-line
  }, [initialEmailVerification])

  const transactionFilter = {
    page: 0,
    size: 5,
    sort: 'reqTime-desc,id-desc'
  }

  return (
    <DashboardWrapper>
      <DashboardLeft>
        <DashboardTitle className='title'>{tr('FRONTEND.DASHBOARD.TITLE', 'Overview')}</DashboardTitle>
        {isOwnerOrAdministrator(customerRole) ? (
          <>
            <AccountSection aggregatedBalance={aggregatedBalance} />

            <ErrorDisplay error={aggregatedBalanceError} />
            <SumSubBanner className='dashboard' />

            <GraphSection />
            <TransactionsSection filter={transactionFilter} />
          </>
        ) : (
          <StaffDashboard />
        )}
      </DashboardLeft>

      <DashboardRight>
        <DashboardRightTitle>{tr('FRONTEND.DASHBOARD.ACTIVITY_TITLE', 'Activity')}</DashboardRightTitle>

        {lastActivitiesFetching ? null : lastActivitiesError ? (
          <ErrorDisplay error={lastActivitiesError} />
        ) : utils.is.validArray(activities) ? (
          activities?.map((item, index) => (
            <span className='line-wrap' key={'dt-' + index}>
              <ActivityDateLine datum={item.date} />
              {item.list.map((activity, index2) => (
                <ActivityInfoLine key={'act-' + index + '-' + index2} activity={activity} />
              ))}
            </span>
          ))
        ) : null}
      </DashboardRight>
    </DashboardWrapper>
  )
}

export default Dashboard
