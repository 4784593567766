import styled from 'styled-components'

export const PasswordTermsWrapper = styled.div`
  width: 100%;
  font-size: 1.2rem;
  font-weight: 500;
  margin: 0.2rem 0 3.7rem 0;
  text-align: left;

  .circle-wrapper {
    padding: 0.5rem 0;
    font-size: 1.3rem;
    line-height: 1.3rem;
    color: #939ea7;

    &.not-done {
      color: #939ea7;
    }

    .icon {
      display: inline-flex;
      width: 1.8rem;
    }

    .circle {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.8rem;
      border-radius: 50%;
      display: inline-block;
      background-color: #939ea7;
    }
  }
`
