import styled from 'styled-components'

export const TransactionsSectionWrapper = styled.div``

export const TransactionsSectionTitle = styled.div`
  color: #85a0ad;
  font-family: inherit;
  font-size: 2.4rem;
  font-style: normal;
  font-weight: 600;
  line-height: 2.9rem;
  margin-bottom: 2rem;
`

export const TransactionsWrapper = styled.div``

export const TransactionsTableContainer = styled.div`
  .data-table-head {
    .data-table-row {
      border-bottom: 1px solid rgba(163, 184, 194, 0.3);

      .data-table-head-cell {
        height: 5rem;
        vertical-align: middle;

        color: #a3b8c2;
        font-family: inherit;
        font-size: 1.2rem;
        font-style: normal;
        font-weight: bold;
        line-height: 1.5rem;
        text-transform: uppercase;
      }
    }
  }

  .data-table-body {
    .data-table-row {
      border-bottom: 1px solid #f4f6f6;
      :last-child {
        border-bottom: none;
      }

      .data-table-cell {
        color: #000000;
        font-family: inherit;
        font-size: 1.4rem;
        font-style: normal;
        font-weight: normal;
        line-height: 1.7rem;

        &.text-upper {
          text-transform: uppercase;
        }
        &.status-failed {
          color: #d13737;
        }
        &.status-cancelled {
          color: #e4c41e;
        }
        &.status-processed {
          color: #34a518;
        }
      }
      .data-table-cell-caption {
        text-transform: none;
      }
    }
  }

  .data-table-cell,
  .data-table-head-cell {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  @media (min-width: 1000px) {
    .data-table-container {
      background: transparent;
      border-radius: 8px;
      box-shadow: 0 0 25px rgba(46, 53, 56, 0.1);
    }

    .data-table {
      border-radius: 8px;

      .data-table-head {
        .data-table-row {
          .data-table-head-cell {
            :first-of-type {
              padding-left: 3rem;
              border-radius: 8px 0 0 0;
            }
            :last-of-type {
              border-radius: 0 8px 0 0;
            }
          }
        }
      }
    }

    .data-table-body {
      .data-table-row {
        .data-table-cell {
          height: 5rem;
          &:first-of-type {
            padding-left: 3rem;
          }
          &:last-of-type {
            padding-right: 2rem;
          }
        }

        :last-of-type {
          .data-table-cell {
            :first-of-type {
              border-radius: 0 0 0 8px;
            }
            :last-of-type {
              border-radius: 0 0 8px 0;
            }
          }
        }
      }
    }
  }
`
