import { useHistory } from 'react-router-dom'
// @ts-ignore
import { tr } from 'mmfintech-commons'
// @ts-ignore
import { Button } from 'mmfintech-portal-commons'
// @ts-ignore
import { useTransactionsFilter, actions, paths } from 'mmfintech-backend-api'
import { AccountOverviewList } from '../account/AccountOverviewList'
import SpendingLimitBarUser from '../requests/SpendingLimitBarUser'
import { useHasWritePermissions } from '../../hooks'
import { ReactComponent as SendIcon } from '../../images/icons/send.svg'
import { StaffDashboardWrapper } from './styled/dashboard.styled'
import TransactionsSection from './elements/TransactionsSection'

export const StaffDashboard = () => {
  const hasAnyPermissions = useHasWritePermissions()
  useTransactionsFilter({ accountId: -1 })
  const history = useHistory()

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }
  return (
    <StaffDashboardWrapper>
      <div className='dashboard-nav'>
        <SpendingLimitBarUser />
        {hasAnyPermissions && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        )}
      </div>
      <AccountOverviewList />
      <TransactionsSection />
    </StaffDashboardWrapper>
  )
}
