import React from 'react'
import PropTypes from 'prop-types'

import { JumpingButtonStyled, JumpingButtonWrapper } from './styled/jumpingButton.styled'

const JumpingButton = ({ icon, label, onClick, ...rest }) => {
  return (
    <JumpingButtonWrapper>
      <JumpingButtonStyled className='round-square-container button' onClick={onClick} {...rest}>
        {icon}
        {label}
      </JumpingButtonStyled>
    </JumpingButtonWrapper>
  )
}

export default JumpingButton

JumpingButton.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.string,
  onClick: PropTypes.func
}
