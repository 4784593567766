import styled from 'styled-components'

export const UnlockedFeatureModalWrapper = styled.div`
    display: grid;
    padding: 5rem;
    width: 100%;

    .content {
        text-align: center;
        row-gap: 20px;
        grid-template-rows: 1fr 1px 1fr;
    }

    .splitter { 
        width: 80%;
        margin: auto;
        margin-top: 1.5rem;
        border-bottom: 2px solid rgba(133, 160, 173, 0.3);;
    }
`