// noinspection DuplicatedCode

import { useContext } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { Button } from 'mmfintech-portal-commons'
import { Callout } from '../../../components'
import { UnlockedFeatureModal } from '../../../components/UnlockedFeatureModal'
import { AccountSectionWrapper, BalanceContainer } from './styled/accountSection.styled'

import { hints } from '../../../constants'
import { actions, isOwnerOrAdministrator, paths } from 'mmfintech-backend-api'
import { useHasWritePermissions } from '../../../hooks'
import { extractCurrencyCode, formatMoney, GlobalContext, isValidArray, tr } from 'mmfintech-commons'

import { ReactComponent as DepositIcon } from '../../../images/icons/deposit.svg'
import { ReactComponent as SendIcon } from '../../../images/icons/send.svg'
import { ReactComponent as ExchangeIcon } from '../../../images/icons/exchange.svg'
import { ReactComponent as RedDotIcon } from '../../../images/icons/new-dot-icon.svg'

const {
  unlocked: { deposit, exchange }
} = hints

const AccountSection = ({ aggregatedBalance }) => {
  const { modalShow } = useContext(GlobalContext)
  const dispatch = useDispatch()
  const { customerRole, hints: seenHints } = useSelector(
    ({ user: { customerRole, hints } }) => ({
      customerRole,
      hints
    }),
    shallowEqual
  )
  const history = useHistory()
  const hasAnyPermissions = useHasWritePermissions()
  const handleDepositClick = () => {
    actions.routing.setDepositOrigin('')
    history.push(paths.banking.deposit())
  }

  const handleSendMoneyClick = () => {
    actions.routing.setWithdrawOrigin('')
    history.push(paths.banking.send())
  }

  const handleExchangeClick = () => {
    actions.routing.setExchangeOrigin('')
    history.push(paths.banking.exchange())
  }

  const calloutProps = {
    isOpen: true,
    position: 'top',
    alignment: 'right',
    arrowOptions: { showArrow: false },
    offset: { x: '1.5rem', y: '1.5rem' },
    content: <RedDotIcon />
  }

  return (
    <AccountSectionWrapper>
      <BalanceContainer>
        {isValidArray(aggregatedBalance) && (
          <>
            <div className='label'>{tr('FRONTEND.DASHBOARD.ACCOUNT_SELECT.TOTAL_LABEL', 'Total account value')}</div>
            <div data-test='balance'>
              {formatMoney(
                aggregatedBalance[aggregatedBalance.length - 1].amount,
                extractCurrencyCode(aggregatedBalance[aggregatedBalance.length - 1])
              )}
            </div>
          </>
        )}
      </BalanceContainer>

      <div className='buttons-container'>
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            type='button'
            color='secondary'
            icon={<DepositIcon />}
            text={
              <Callout {...calloutProps} hintKey={deposit}>
                {tr('FRONTEND.DASHBOARD.BUTTONS.DEPOSIT', 'Deposit')}
              </Callout>
            }
            onClick={async () => {
              await handleDepositClick()
              if (seenHints && !seenHints?.find(hint => hint?.hintKey === 'unlocked-depoists')?.isSeen) {
                dispatch(actions.merchant.setUserHints({ hintKey: deposit }, seenHints))
                modalShow({
                  content: <UnlockedFeatureModal type='deposit' />
                })
              }
            }}
            data-test='deposit-button'
            data-for='button-tooltip'
          />
        )}
        {hasAnyPermissions && (
          <Button
            type='button'
            color='secondary'
            icon={<SendIcon />}
            text={tr('FRONTEND.DASHBOARD.BUTTONS.SEND', 'Send')}
            onClick={handleSendMoneyClick}
            data-test='send-money-button'
          />
        )}
        {isOwnerOrAdministrator(customerRole) && (
          <Button
            type='button'
            color='secondary'
            icon={<ExchangeIcon />}
            text={
              <Callout {...calloutProps} hintKey={exchange}>
                {tr('FRONTEND.DASHBOARD.BUTTONS.EXCHANGE', 'Exchange')}
              </Callout>
            }
            onClick={async () => {
              await handleExchangeClick()
              if (seenHints && !seenHints?.find(hint => hint?.hintKey === 'unlocked-exchange')?.isSeen) {
                dispatch(actions.merchant.setUserHints({ hintKey: exchange }, seenHints))
                modalShow({ content: <UnlockedFeatureModal type='exchange' /> })
              }
            }}
            data-test='exchange-button'
            data-for='button-tooltip'
          />
        )}
      </div>
    </AccountSectionWrapper>
  )
}

export default AccountSection
